<template>
  <div>Dashboard</div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  }
}
</script>